
import moment, { Moment} from 'moment'
import { Options, Vue } from 'vue-class-component';
import { Provide, Watch } from 'vue-property-decorator';
import { detail, update } from '@/api/order'
import { Form, message } from 'ant-design-vue';
import { Greeter } from '@/api/platform'
import { deepCopy, throttle } from '@/untils/until'
import {
  PlusCircleOutlined,
} from '@ant-design/icons-vue';
interface IOrderdata {
  // 1card
  ddbh: string,
  dduuid: string,
  fdfs: string,
  sfd: string,
  sfdName: string,
  fdsj: Moment,
  sfdxxdz: string,
  mdd: string,
  mddName: string,
  mddxxdz: string,
  hwdw: string,
  hwzl: number,
  hwtj: number,
  hwsshyName: string,
  hwmc: string,
  lc: string, //路程
  jdsj: Moment,
  jdfs: string,
  zxfs: string,
  thdfjPic: string, // 提货单
  rkdfjPic: string,  // 入库单
  hwspbm?: string,
  ddjsssqy?: string

  // 2card
  fwsj: string,
  qsfwsj: Moment,
  jsfwsj: Moment,
  jssj: Moment,
  gps: string,
  // 3card
  fwfddje: number,
  ptfwf: number,
  hyxje: number,
  zk: number,
  fbfddje: number,
  zffs: string,
  yhkh: string,
  skrxm: string,
  skrsfzjhm: string,
  yhls: string,
  zfpz: string,
  qtjl?: string, //邮费
  // 4card
  fbfuuid: string,
  fbfnsrmc: string,
  fbfnsrsbh: string,
  fbfxm?: string, 
  fbfsfzjhm?: string,
  //5card
  fwfuuid: string,
  fwfxm: string,
  fwfsfzjlx: string,
  fwfsfzjlxName:string,
  fwfsfzjhm: string,
  cc: number,
  cx: string,
  cycph: string,
}
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  preview?: string;
  originFileObj?: any;
}
interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
@Options({
  components: {
    PlusCircleOutlined,
  },
})

export default class OrderDetail extends Vue {
  url:string = process.env.VUE_APP_BASEURL
  header: any = {
    Authorization: `Bearer ${localStorage.tsToken}`
  }
  previewLeftVisible: boolean = false
  previewRightVisible: boolean = false
  previewLeftImage: string | undefined = ''
  previewRightImage: string | undefined = ''
  fileLeftList: any = []
  fileRightList: any = []
  placeData: any = []
  placeStartData: any = []
  placeEndData: any = []
  formRef: any = {}
  visible: boolean = false
  path: any = ''
  checked: boolean = true
  timeout: any = null
  pageSNum: number = 1
  pageENum: number = 1
  IDData: any = []
  disabledDate: any = (current: Moment)=> {
    return current && current > moment().endOf('day');
  }
  @Provide() orderform: IOrderdata = {
    // 1card
    ddbh: "",
    dduuid: "",
    fdfs: "",
    sfd: "",
    sfdName: "",
    fdsj: moment(),
    sfdxxdz: "",
    mdd: "",
    mddName: "",
    mddxxdz: "",
    hwdw: "",
    hwzl: 0,
    hwtj: 0,
    hwsshyName: "",
    hwmc: "",
    lc: "", //路程
    jdsj: moment(),
    jdfs: "",
    zxfs: "",
    thdfjPic: "",
    rkdfjPic: '',
    hwspbm: '',
    ddjsssqy: '',

    // 2card
    fwsj: "",
    qsfwsj: moment(),
    jsfwsj: moment(),
    jssj: moment(),
    gps: "",
    // 3card
    fwfddje: 0,
    ptfwf: 0,
    hyxje: 0,
    zk: 0,
    fbfddje: 0,
    zffs: "",
    yhkh: "",
    skrxm: "",
    skrsfzjhm: "",
    yhls: "",
    zfpz: "",
    qtjl: "", //邮费
    // 4card
    fbfuuid: "",
    fbfnsrmc: "",
    fbfnsrsbh: "",
    fbfxm: "", 
    fbfsfzjhm: "",
    //5card
    fwfuuid: "",
    fwfxm: "",
    fwfsfzjlx: "",
    fwfsfzjlxName:"",
    fwfsfzjhm: "",
    cc: 0,
    cx: "",
    cycph: "",
  }
  rules: any = {
    // 1 card
    fdfs: [{required: true, message: '请填写', trigger: 'change'}],
    sfd: [{required: true, message: '请填写', trigger: 'blur'}],
    fdsj: [{required: true, message: '请填写', trigger: 'change', type: 'object'}],
    sfdxxdz: [{required: true, message: '请填写', trigger: 'blur'}],
    mdd: [{required: true, message: '请填写', trigger: 'blur'}],
    mddxxdz: [{required: true, message: '请填写', trigger: 'blur'}],
    hwdw: [{required: true, message: '请填写', trigger: 'blur'}],
    hwzl: [{ required: true, message: '请填写'}],
    hwtj: [{required: true, message: '请填写'}],
    hwsshyName: [{required: true, message: '请填写', trigger: 'blur'}],
    hwsshy: [{required: true, message: '请填写', trigger: 'blur'}],
    lc: [{required: true, message: '请填写',trigger: 'blur'},
     {
        pattern: /^(\-|\+)?\d+(\.\d+)?$/,
        message: '请输入数字',
        trigger: 'blur'
      }
    ],
    jdsj: [{required: true, message: '请填写', trigger: 'change', type: 'object'}],
    jdfs: [{required: true, message: '请填写', trigger: 'blur'}],
    zxfs: [{required: true, message: '请填写', trigger: 'blur'}],
    thdfjPic: [{required: true, message: '请上传提货单'}],
    rkdfjPic: [{required: true, message: '请上传入库单'}],

    // 2 card
    fwsj: [{required: true, message: '请填写', trigger: 'blur'}],
    qsfwsj: [{required: true, message: '请填写', trigger: 'change', type: 'object'}],
    jsfwsj: [{required: true, message: '请填写', trigger: 'change', type: 'object'}],
    jssj: [{required: true, message: '请填写', trigger: 'change', type: 'object'}],

    // 3 card
    fwfddje: [{required: true, message: '请填写'}],
    ptfwf: [{required: true, message: '请填写'}],
    hyxje: [{required: true, message: '请填写'}],
    zk: [{required: true, message: '请填写'}],
    fbfddje: [{required: true, message: '请填写'}],
    zffs: [{required: true, message: '请填写', trigger: 'change'}],
    yhkh: [{required: true, message: '请填写', trigger: 'blur'},
      {
        pattern: /^[1-9]\d{9,29}$/,
        message: '银行卡号格式错误',
        trigger: 'blur'
      }
    ],
    skrxm: [{required: true, message: '请填写', trigger: 'blur'}],
    skrsfzjhm: [{required: true, message: '请填写', trigger: 'blur'},
     {
       pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
       message: '身份证格式错误',
       trigger: 'blur'
     }],
    yhls: [{required: true, message: '请填写', trigger: 'blur'}],
    zfpz: [{required: true, message: '请填写', trigger: 'blur'}],

    // 4 card
    fbfnsrmc: [{required: true, message: '请填写', trigger: 'blur'}],
    fbfnsrsbh: [{required: true, message: '请填写', trigger: 'blur'}],
    fbfsfzjhm: [{
      pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
      message: '身份证格式错误',
      trigger: 'blur'
    }],
    // 5 card
    fwfxm: [{required: true, message: '请填写', trigger: 'blur'}],
    fwfsfzjhm: [
      {required: true, message: '请填写', trigger: 'blur'},
      {
        pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
        message: '身份证格式错误',
        trigger: 'blur'
      }
    ],
    cc: [{required: true, message: '请填写', trigger: 'blur'}],
    cx: [{required: true, message: '请填写', trigger: 'blur'}],
    cycph: [{required: true, message: '请填写', trigger: 'blur'},
      {pattern: /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)/,message: '车牌号格式错误',trigger: 'blur'}],
  }
  created() {
    /**
     * @description: 订单详情接口
     * @param {*}
     * @return {*}
     */    
    const id = this.$route.params.id
    detail(id).then((res: any)=> {
      if (res.code == 200) {
        this.orderform = { ...res.data}
        // 缩略图
        this.fileLeftList = [{
          uid: '-1',
          name: 'left.png',
          status: 'done',
          url: res.data.thdfjThn
        }]
        this.fileRightList = [{
          uid: '-2',
          name: 'right.png',
          status: 'done',
          url: res.data.rkdfjThn,
        }]
        // 原图
        this.previewLeftImage = res.data.thdfjPic
        this.previewRightImage = res.data.rkdfjPic
        this.orderform.fdsj = moment(res.data.fdsj, 'YYYY-MM-DD HH:mm:ss')
        this.orderform.jdsj = moment(res.data.jdsj, 'YYYY-MM-DD HH:mm:ss')
        this.orderform.qsfwsj = moment(res.data.qsfwsj, 'YYYY-MM-DD HH:mm:ss')
        this.orderform.jsfwsj = moment(res.data.jsfwsj, 'YYYY-MM-DD HH:mm:ss')
        this.orderform.jssj = moment(res.data.jssj, 'YYYY-MM-DD HH:mm:ss')
      }
    })
  }
  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  mounted() {
    this.getPlaceDate(3)
  }
  backList() {
    sessionStorage.setItem('isDetail', 'true')
    this.$router.back()
  }
  getSChange(value: string) {
    this.orderform.sfd = value
  }
  getEChange(value: string) {
    this.orderform.mdd = value
  }
  /**
   * @description: 将后台传过来的时间转为moment格式的字符窜，再将字符窜转化为时间戳
   * @param {*}
   * @return {*}
   */  
  changeTime() {
    const start = moment(moment(this.orderform.qsfwsj).format('YYYY-MM-DD HH:mm:ss')).valueOf()
    const end = moment(moment(this.orderform.jsfwsj).format('YYYY-MM-DD HH:mm:ss')).valueOf()
    // 如果结束时间 - 开始时间 > 1小时的时间戳 则转化为 X天X时
    if (end - start > 60*60*1000) {
      const time = moment.duration(end - start, 'milliseconds')
      const days = Math.floor(time.asDays())
      const hours = Math.floor(time.asHours()) - days * 24
      this.orderform.fwsj = `${days}天${hours}小时`
    } else {
      this.orderform.fwsj = '0小时'
    }
  }
  /**
   * @description: 获取下拉框焦点事件
   * @param {*} type
   * @return {*}
   */  
  handleFocus(type:number) {
    this.pageSNum = 1
    this.pageENum = 1
    if ( type == 1) {
      this.placeStartData = this.placeData
    } else {
      this.placeEndData = this.placeData
    }
  }
  /**
   * @description: 下拉列表 下拉加载更多
   * @param {*}
   * @return {*}
   */  
  onStartScroll(e: any) :void {
    const { scrollHeight, scrollTop, clientHeight } = e.target
    if (scrollHeight - scrollTop === clientHeight) {
    this.pageSNum++
    this.getPlaceDate(1, '', this.pageSNum) // 调用接口
    }
  }
  /**
   * @description: 下拉列表 下拉加载更多
   * @param {*}
   * @return {*}
   */  
  onEndScroll(e: any) :void {
    const { scrollHeight, scrollTop, clientHeight } = e.target
    if (scrollHeight - scrollTop === clientHeight) {
    this.pageENum++
    this.getPlaceDate(2, '', this.pageENum) // 调用接口
    }
  }
  /** 公用方法 获得地点（市、县）
   * @description: 
   * @param {*}
   * @return {*}
  */    
  getPlaceDate(type:number = 1, val:string = '', pageNum: number = 1) {
    const gree = new Greeter(val, 2, pageNum)
    gree.greet().then((res: any)=> {
      if (res.code == 200) {
        if (type == 1) {
          if (val || pageNum === 1) {
            this.placeStartData = res.data.list
          } else {
            this.placeStartData = [...this.placeStartData, ...res.data.list]
          }
        } 
        if (type == 2) {
          if (val || pageNum === 1) {
            this.placeEndData = res.data.list
          } else {
            this.placeEndData = [...this.placeEndData, ...res.data.list]
          }
        }
        if (type == 3) {
          this.placeData = deepCopy(res.data.list)
        }
      }
    })
    this.path = this.$route.query.path as string
  }
  /**
   * @description: 开启图片预览
   * @param {*} file
   * @return {*}
   */  
  async handleLPreview(file: FileItem) {
    if (!file.url && !file.preview) {
      file.preview = (await this.getBase64(file.originFileObj)) as string;
    }
    this.previewLeftImage = this.previewLeftImage ? this.previewLeftImage: file.url || file.preview;
    this.previewLeftVisible = true;
  }
  async handleRPreview(file: FileItem) {
    if (!file.url && !file.preview) {
      file.preview = (await this.getBase64(file.originFileObj)) as string;
    }
    this.previewRightImage = this.previewRightImage ? this.previewRightImage: file.url || file.preview;
    this.previewRightVisible = true;
  }
  /**
   * @description: 关闭图片预览
   * @param {*}
   * @return {*}
   */  
  handleCancel():void {
    this.previewLeftVisible = false
    this.previewRightVisible = false
  }
  /**
   * @description: 下拉框搜索防抖
   * @param {*}
   * @return {*}
   */  
  fetchStartUser(value: string) :void {
    if (this.timeout) {
      clearInterval(this.timeout)
    } 
    this.timeout = setTimeout(()=> {
      this.getPlaceDate(1, value)
    }, 800)
  }
  fetchEndUser(value: string) :void {
    if (this.timeout) {
      clearInterval(this.timeout)
    } 
    this.timeout = setTimeout(()=> {
      this.getPlaceDate(2, value)
    }, 800)
  }
  RemoveLImg(file: File): void {
    this.previewLeftImage = ''
  }
  RemoveRImg(file: File): void {
    this.previewRightImage = ''
  }
  /**
   * @description: 提货单图片路径赋值
   * @param {*}
   * @return {*}
   */  
  handleLeftChange({ fileList: newFileList }: FileInfo):void {
    this.fileLeftList = newFileList;
    if (this.fileLeftList[0]&&this.fileLeftList[0].status == 'done') {
      this.orderform.thdfjPic = this.fileLeftList[0].response.data.url
    } else {
       this.orderform.thdfjPic = ''
    }
    this.formRef = this.$refs.formRef
    this.formRef.validateFields('thdfjPic');
  }
    /**
   * @description: 入库单图片路径赋值
   * @param {*}
   * @return {*}
   */
  handleRightChange({ fileList: newFileList }: FileInfo):void {
    this.fileRightList = newFileList;
    if (this.fileRightList[0] &&this.fileRightList[0].status == 'done') {
      this.orderform.rkdfjPic = this.fileRightList[0].response.data.url
    } else {
      this.orderform.rkdfjPic = ''
    }
    this.formRef = this.$refs.formRef
    this.formRef.validateFields('rkdfjPic');
  }
  /**
   * @description: 取消
   * @param {*}
   * @return {*}
   */  
  onCancel():void {
    if (this.path === 'error') {
      this.$router.push('/errororder')
    } else {
      this.$router.push('/pushorder')
    }
  }
  /**
   * @description: 提交
   * @param {*}
   * @return {*}
   */  
  onSubmit() :void {
    throttle(this.save, 1000)()
  }
  save() {
    this.formRef = this.$refs.formRef
    this.formRef.validate().then(() => {
      const newForm = deepCopy(this.orderform)
      newForm.fdsj = moment(this.orderform.fdsj).format('YYYY-MM-DD HH:mm:ss')
      newForm.jdsj = moment(this.orderform.jdsj).format('YYYY-MM-DD HH:mm:ss')
      newForm.qsfwsj = moment(this.orderform.qsfwsj).format('YYYY-MM-DD HH:mm:ss')
      newForm.jsfwsj = moment(this.orderform.jsfwsj).format('YYYY-MM-DD HH:mm:ss')
      newForm.jssj = moment(this.orderform.jssj).format('YYYY-MM-DD HH:mm:ss')
      update(newForm).then((res: any)=> {
      if (res.code == 200) {
        message.success('数据保存成功', 5)
        this.onCancel()
      }
    })
    }).catch((error: any) => {
    })
  }
}
